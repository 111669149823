@import '~@mo/design-catalogue/src/breakpoints';
@import '~@mo/design-catalogue/src/colors';
@import '~@mo/design-catalogue/src/spacing';
@import '~@mo/design-catalogue/src/buttons';
@import '../../index';

.activationCompletePage {
  margin-top: $spacing-xl;
  background-color: $blue;
  padding-bottom: $spacing-large;
  min-height: calc(100vh - #{$header-height + $spacing-large});

  @include above-mobile {
    padding-bottom: $spacing-xl;
    min-height: calc(100vh - #{$header-height + $spacing-xl});
  }

  &::before {
    background-color: $blue;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }

  .background {
    margin-top: -$spacing-xl;
    width: 100%;
    height: auto;
  }

  .redCar,
  .blueCar {
    padding-top: $spacing-small;
    position: relative;
    height: 80px;
    animation-duration: 2s;
    transform: translateX(-50%);

    @include above-mobile {
      height: 140px;
    }
  }

  .blueCar {
    animation-name: slideInBlue;
    left: 55vw;
  }

  .redCar {
    animation-name: slideInRed;
    left: 43vw;
    position: absolute;
    margin-top: 1vh;
  }

  @keyframes slideInBlue {
    from {
      left: -300px;
    }
    to {
      left: 55vw;
    }
  }

  @keyframes slideInRed {
    from {
      left: -300px;
    }
    to {
      left: 43vw;
    }
  }

  .content,
  .title {
    background-color: transparent;
    color: $white;
    text-align: center;

    @include form-width();
  }

  .content {
    margin: 0 auto;
    padding: 0 15px;

    @include above-mobile {
      padding: 0;
    }
  }

  .button {
    @include style-button($blue, $white);
    @include focus();
    margin: $spacing-medium 0;

    @include above-mobile {
      width: 255px;
      display: inline-block;
    }
  }
}
