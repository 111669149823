@import '~@mo/design-catalogue/src/breakpoints';
@import '~@mo/design-catalogue/src/colors';
@import '~@mo/design-catalogue/src/typography';
@import '~@mo/design-catalogue/src/focus';

$overflowGutterAboveTabletWithSubPixelRendering: calc(1px + (100vw - 1200px) / 2);

//* { background-color: rgba(255,0,0,.4); }
//* * { background-color: rgba(0,255,0,.4); }
//* * * { background-color: rgba(0,0,255,.4); }
//* * * * { background-color: rgba(255,0,255,.4); }
//* * * * * { background-color: rgba(0,255,255,.4); }
//* * * * * * { background-color: rgba(255,255,0,.4); }
//* * * * * * * { background-color: rgba(255,0,0,.4); }
//* * * * * * * * { background-color: rgba(0,255,0,.4); }
//* * * * * * * * * { background-color: rgba(0,0,255,.4); }

html {
  box-sizing: border-box;

  & *,
  & *::before,
  & *::after {
    box-sizing: inherit;
  }
}

body {
  margin: 0;
  padding: 0;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: $font-family;
  font-size: $mobile-font-size;
  line-height: $mobile-line-height;

  color: $greyscale6;

  @include small-devices {
    font-size: $desktop-font-size;
    line-height: $desktop-line-height;
  }
}

html,
body {
  min-height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a {
  color: $blue;

  @include focus();
}

@mixin error-size {
  width: 100%;

  @include above-tablet {
    width: 540px;
  }
}

@mixin title-size {
  width: 100%;

  @include above-mobile {
    width: 445px;
  }

  @include above-tablet {
    width: 540px;
  }
}

@mixin form-contents-size {
  @include above-mobile {
    width: 455px;
  }
}

@mixin form-width {
  width: 100%;

  @include above-mobile {
    width: 690px;
  }

  @include above-tablet {
    width: $max-container-width-xl - (4 * 95);
  }
}

@mixin primary-button {
  width: 100%;
  max-width: 350px;
  font-size: $mobile-font-size;
  min-height: $spacing-xl;

  @include above-mobile {
    max-width: 210px;
    font-size: $desktop-font-size;
    min-height: $spacing-xxl;
  }

  @include above-tablet {
    max-width: 255px;
  }
}

h1 {
  margin: $spacing-large 0 $spacing-medium 0;
  font-size: $mobile-font-size-h1;
  line-height: $mobile-line-height-h1;
  color: $blue;

  @include above-mobile {
    margin: $spacing-xl 0 $spacing-large 0;
    font-size: $desktop-font-size-h1;
    line-height: $desktop-line-height-h1;
  }
}

h3 {
  font-size: $mobile-font-size-h3;
  line-height: $line-height-h3;

  @include above-mobile {
    font-size: $desktop-font-size-h3;
  }
}

h4 {
  font-size: $mobile-font-size-h3;
  font-weight: normal;
  line-height: $line-height-h3;

  @include above-mobile {
    font-size: $desktop-font-size-h3;
  }
}
