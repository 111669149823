@import '~@mo/design-catalogue/src/spacing';
@import '~@mo/design-catalogue/src/breakpoints';
@import '../../index';

.technicalError {
  @include form-width();
  margin: $spacing-medium 0;

  @include above-mobile {
    margin-top: $spacing-xl;
    margin-bottom: 0;
  }
}

.technicalErrorHint {
  line-height: 22px;
  padding-bottom: 3px;
  align-items: center;
  margin-right: $spacing-large;
  display: inline-flex;
  vertical-align: middle;

  @include above-mobile {
    line-height: 26px;
  }
}

.technicalErrorTitle {
  padding-left: 10px;
}

.technicalErrorMessage {
  padding-left: 0;
}
