@import '~@mo/design-catalogue/src/colors';

.rules {
  list-style-position: inside;
}

.validationGood {
  color: $green-tint-1;
  list-style-image: url('./assets/tick.svg');
}

.validationBad {
  color: $red;
  list-style-image: url('./assets/cross.svg');
}
