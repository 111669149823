@import 'node_modules/@mo/design-catalogue/src/colors';
@import 'node_modules/@mo/design-catalogue/src/spacing';
@import 'node_modules/@mo/design-catalogue/src/breakpoints';
@import 'node_modules/@mo/design-catalogue/src/typography';
@import 'node_modules/@mo/design-catalogue/src/focus';
@import 'node_modules/@mo/design-catalogue/src/buttons';
@import 'src/index';

.signInPromptCard {
  background-color: #fff;
  padding: $spacing-large;
  margin-top: $spacing-xl;
  margin-bottom: $spacing-large;
  border-radius: 6px;
  box-shadow: 0 3px 19px 0 rgba(0, 0, 0, 0.1);

  @include above-mobile {
    margin-top: $spacing-xxl;
    padding: $spacing-large $spacing-xl;
  }
}

.signInPromptHeading {
  display: block;
  font-weight: bold;
  margin: 0;
  font-size: $mobile-font-size;

  @include above-mobile() {
    font-size: $desktop-font-size;
  }
}

.signInButton {
  @extend %button-secondary;
  font-size: $mobile-font-size;

  @include above-mobile() {
    font-size: $desktop-font-size;
  }
}
