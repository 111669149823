@import 'node_modules/@mo/design-catalogue/src/breakpoints';
@import 'node_modules/@mo/design-catalogue/src/colors';
@import 'node_modules/@mo/design-catalogue/src/spacing';
@import 'node_modules/@mo/design-catalogue/src/focus';

.passwordInputGroup {
  margin-top: $spacing-medium;
}

.inputHints {
  .hint {
    margin-bottom: $spacing-xs;
  }
}

.inputLabel {
  display: block;
  font-weight: bold;
  margin-bottom: $spacing-xxs;
}

.errorPasswordInputGroup {
  width: auto;
  border-left: solid 6px $indicator-red;
  padding-left: $spacing-small;
  padding-top: 0;
}

.passwordInputFieldWithToggle {
  display: flex;
  margin-top: $spacing-xs;
  height: 46px;
  width: 100%;
  max-width: 350px;

  @include above-mobile {
    max-width: 330px;
  }

  @include above-tablet {
    max-width: 350px;
  }

  .inputField {
    flex: 2;
    height: 100%;
    border-radius: 6px 0 0 6px;
    box-shadow: inset 0 3px 3px 0 rgba(0, 0, 0, 0.2);
    border: 2px solid #9b9b9b;
    border-right: 0;
    background-color: #ffffff;
    padding: $spacing-small;

    @include insetFocus();
  }

  .showHideButton {
    flex: 1;
    height: 100%;
    border: 2px solid #9b9b9b;
    border-radius: 0 6px 6px 0;
    box-shadow: inset 0 3px 3px 0 rgba(0, 0, 0, 0.2);
    font-size: 12px;
    color: $indicator-white;
    background-color: $blue;
    white-space: nowrap;

    @include insetFocus();
  }

  .errorInputField {
    box-shadow: none;
    border: 3px solid $indicator-red;
    border-right: 0;
  }

  .errorButton {
    box-shadow: none;
    border: 3px solid $indicator-red;
  }
}

.inlineErrors {
  list-style: none;
  margin: 0;
  width: 100%;
  max-width: 350px;

  @include small-devices {
    width: 350px;
  }
}

.inlineErrorMessage {
  color: $indicator-red;
  margin: 0 0 $spacing-xxs 0;
  font-weight: bold;
}
