@import '~@mo/design-catalogue/src/spacing';
@import '~@mo/design-catalogue/src/breakpoints';

.padlockIcon {
  display: none;

  @include above-mobile {
    display: inline-block;
  }
}

.tablet {
  display: none;

  @media (min-width: $min-device-width-md) and (max-width: $min-device-width-xl) {
    display: inline-block;
  }
}

.desktop {
  display: none;

  @include above-tablet {
    display: inline-block;
  }
}