@import 'node_modules/@mo/design-catalogue/src/spacing';
@import 'node_modules/@mo/design-catalogue/src/breakpoints';
@import 'node_modules/@mo/design-catalogue/src/buttons';

.errorPage {
  margin: $spacing-small 0;
  text-align: left;
  width: 100%;

  // the below overrides standard h1
  .errorHeading {
    margin-bottom: 0;
  }

  .errorText {
    font-size: $mobile-font-size;
    line-height: $mobile-line-height;
    margin: $spacing-medium 0 0 0;

    @include above-mobile {
      font-size: $desktop-font-size;
      line-height: $desktop-line-height;
      margin-top: $spacing-large;
    }
  }
}

.linkExpiredErrorPageButton {
  @include style-button($white, $blue);
  margin-top: $spacing-xl;
  width: 100%;
  font-size: $mobile-font-size;

  @include above-mobile {
    display: inline-block;
    max-width: 255px;
    font-size: $desktop-font-size;
  }
}
