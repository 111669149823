@import '~@mo/design-catalogue/src/spacing';
@import '~@mo/design-catalogue/src/breakpoints';

.cardLink {
  color: inherit;
  border: none;
  font: inherit;
  width: inherit;
  text-decoration: none;
  display: flex;

  &:visited {
    border: none;
  }
  margin-bottom: $spacing-medium;

}

.card {
  width: 100%;
  padding: 16px 21px;
  margin: 0;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  display: flex;

  align-items: center;

}

.cardIcon {
  padding-right: 22px;
}

.infoHeader {
  margin: 0 0 7px;
  font-weight: bold;

  @include above-mobile() {
    margin: 0;
  }
}

.infoBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: inherit;
}
