@import '~@mo/design-catalogue/src/spacing';
@import '~@mo/design-catalogue/src/breakpoints';
@import '../../../index';

.surname {
  margin: $spacing-large 0 0 0;
}

.beforeForm {
  & p {
    margin-bottom: $spacing-small;
  }

  @include above-mobile{
    margin-bottom: $spacing-xl;
    margin-top: $spacing-xl;

    & p {
      margin-bottom: $spacing-large;
    }
  }
  margin-bottom: $spacing-large;
}

.continueButton {
  width: 100%;
  margin: $spacing-xl 0 $spacing-medium 0;
  min-height: 46px;

  @include small-devices {
    min-height: $font-size-xxxl;
    width: 58%;
  }
}
