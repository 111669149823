@import '~@mo/design-catalogue/src/spacing';
@import '~@mo/design-catalogue/src/breakpoints';
@import '../../../index';

.emailAddressForm {
  @include form-contents-size();
  margin-top: $spacing-large;
  margin-bottom: $spacing-xl;
}

.saveButton {
  width: 100%;
  margin-top: $spacing-xl;
  margin-bottom: $spacing-medium;
  min-height: 46px;

  @include small-devices {
    min-height: $font-size-xxxl;
    width: 68%;
  }
}
