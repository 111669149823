@import '~@mo/design-catalogue/src/spacing';
@import '~@mo/design-catalogue/src/breakpoints';
@import '~@mo/design-catalogue/src/buttons';

.backToAccountButton {
  @extend %button-secondary;

  display: flex;
  align-items: center;

  @include above-mobile {
    width: 261px;
  }
}

.icon {
  padding-right: 1em;
}

.text {
  justify-self: center;
  width: 100%;
}
