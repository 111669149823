@import '~@mo/design-catalogue/src/spacing';
@import '../../index';

.page {
  margin: $spacing-xl auto $spacing-large auto;
  padding: 0 15px;

  @include above-mobile {
    margin: $spacing-xxxl auto $spacing-xxl auto;
    padding: 0;
  }

  @include form-width();
}

.back {
  margin-bottom: $spacing-large;

  @include above-mobile {
    margin-bottom: $spacing-xl;
  }
}

.title {
  @include title-size();
}

.fullWidthTitle {
  width: 100%;
}

.contents {
  display: flex;
}

.form {
  flex-grow: 1;
}

.securityPromise {
  @include above-mobile {
    margin-left: $spacing-xxxl;
  }
}
