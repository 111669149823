@import '~@mo/design-catalogue/src/spacing';
@import '~@mo/design-catalogue/src/breakpoints';

@keyframes placeHolderShimmer {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.animatedBackground {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.065) 8%, rgba(0, 0, 0, 0.13) 18%, rgba(0, 0, 0, 0.065) 33%);
  background-size: 1000px 104px;
  height: 43px;
  position: relative;
}

.shimmer {
  width: 100%;
  height: 26px;
  margin: $spacing-large 0;
  @extend .animatedBackground;

  @include medium-devices {
    margin: $spacing-xxl 0;
  }

  &:first-child {
    margin-top: 0;
  }
}

.line {
  margin-top: 10px;
  border-top: 1px solid #979797;
}
