@import '~@mo/design-catalogue/src/spacing';
@import '~@mo/design-catalogue/src/breakpoints';
@import '../../../index';

.startCreateButton {
  width: 100%;
  margin: $spacing-large 0 $spacing-xl 0;
  min-height: 46px;

  @include small-devices {
    margin-top: $spacing-large;
    min-height: $font-size-xxxl;
    width: 35%;
  }
}

.image {
  width: 100%;

  @include small-devices {
    max-width: max-content;
  }
}

.imageDiv {
  padding: 0 $spacing-large;

  @include small-devices {
    width: 100%;
    padding: $spacing-medium 0 0 0;
    display: flex;
    justify-content: center;
  }
}

.form {
  padding-top: $spacing-medium;

  @include small-devices {
    padding-top: $spacing-xl;
  }
}

.checkButtonGroup {
  border: none;
  padding: 0;
}

.signIn {
  width: 100%;

  @include above-mobile {
    width: 65%;
  }
}

.questionHeading {
  color: $blue;
  margin-top: $spacing-large;
  margin-bottom: $mobile-gutter;
  font-size: $mobile-font-size;

  @include above-mobile {
    font-size: $font-size-large;
    margin-bottom: $spacing-medium;
    margin-top: $spacing-xl;
  }
}

.readyText {
  margin-top: 0;
}

.precheckImage {
  width: 100%;
  content: url('./assets/precheckMobileImage.svg');
  margin-top: $spacing-xl;
  margin-bottom: $spacing-xl;

  @include above-mobile {
    margin-top: 0;
    width: auto;
    content: url('./assets/precheckDesktopImage.svg');
  }
}

.introText {
  margin-top: 0;
}

.infoHeaderText {
  margin-top: $spacing-large;
  @include above-mobile{
    margin-top: $spacing-xl;
  }
}

.precheckForm {
  margin-bottom: $spacing-large;
  @include above-mobile {
    margin-bottom: $spacing-xl;
  }
}

.unavailableInformation {
  border-top-style: solid;
  border-color: #979797;
  border-width: 1px;
}

.unavailable {
  li {
    list-style: outside;
    margin-left: 20px;
    margin-bottom: $spacing-xs;

    @include above-mobile {
      margin-bottom: $spacing-xs;
    }
  }
}

.unavailableOld {
  li {
    list-style: outside;
    margin-left: 20px;
    margin-bottom: $spacing-xs;

    @include above-mobile {
      margin-bottom: $spacing-large;
    }
  }
}

.cardLink {
  text-decoration: none;
  color: $black;

  &:visited {
    text-decoration: none;
    color: $black;
  }

  &:focus {
    text-decoration: none;
    color: $black;
  }
}

.radioGroup {
  fieldset {
    padding: 0;
  }
}

.infoCards {
  margin-bottom: $spacing-large;
  @include above-mobile {
    margin-bottom: $spacing-xl;
  }
}

.signInLink {
  margin-bottom: $spacing-large;
}

