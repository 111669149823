h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

input {
  border:none;
  background-image:none;
  background-color:transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

ul {
  margin: 0;
  padding: 0;
}

svg {
  overflow: visible;
}
