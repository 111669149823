@import 'node_modules/@mo/design-catalogue/src/spacing';
@import 'node_modules/@mo/design-catalogue/src/colors';
@import 'node_modules/@mo/design-catalogue/src/breakpoints';

.errorCode {
  padding: $spacing-xxs $spacing-xs;
  border-radius: 5px;
  border: 1px solid $red-tint-2;
  display: block;
  margin-top: $spacing-small;
  margin-bottom: $spacing-xs;
  font-weight: bold;
  color: $greyscale6;
  width: 100%;
  text-align: center;

  @include above-mobile {
    padding: $spacing-xxs $spacing-xs;
    border-radius: 5px;
    border: 1px solid $red-tint-2;
    display: inline-flex;
    width: auto;
    margin-top: 0;
    margin-bottom: $spacing-large;
    font-weight: bold;
    color: $greyscale6;
  }
}
